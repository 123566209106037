import "./Masonry.scss";
import { ReactNode, useEffect, useRef, useState } from "react";
import Masonry from "react-masonry-css";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { usePathname } from "next/navigation";

interface RecommendPropsI {
  children: ReactNode;
  loading: boolean;
  title: string;
  breakpointColumnsObj: any;
  showRecommended?: boolean;
  subtitle?: string;
}
function MasonryComponent(props: RecommendPropsI) {
  const [cards, setCards] = useState(Array.from({ length: 10 }));
  const recommended = useRef<any>();
  //checar

  function getRandomHeight() {
    const heights = [350, 400, 450, 500, 550];
    const randomIndex = Math.floor(Math.random() * heights.length);
    return heights[randomIndex];
  }

  const goToRecommended = () => {
    window.scrollTo({
      top: recommended.current.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    props.showRecommended && goToRecommended();
  }, [props.showRecommended]);

  return (
    <div ref={recommended} className="recommend-main-container">
      <h2>{props.title}</h2>
      {props.subtitle && props.showRecommended && <h3>{props.subtitle}</h3>}
      <Masonry
        breakpointCols={props.breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {props.loading
          ? cards.map((item, i) => {
              return (
                <div
                  className="skeleton-card"
                  style={{ height: getRandomHeight() }}
                  key={"skeleton-card-" + i}
                >
                  <CustomSpinner size={5} borderWidth={3} />
                </div>
              );
            })
          : props.children}
      </Masonry>
    </div>
  );
}

export default MasonryComponent;
