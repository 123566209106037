import "./GalleriesSlideCard.scss";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { nameSlug } from "@/utils/Functions/NameSlug";
import Link from "next/link";
import { GalleryI } from "@/interfaces/Galleries";

interface GalleriesSlideCardPropsI {
  gallery: GalleryI;
  loading: boolean;
}

function GalleriesSlideCard(props: GalleriesSlideCardPropsI) {
  const { loading, gallery } = props;

  if (loading) {
    return (
      <div className="galleries-slide-card loading">
        <CustomSpinner size={5} borderWidth={3} />
      </div>
    );
  }

  return (
    <Link href={"/" + nameSlug(gallery.name)}>
      <div className="galleries-slide-card">
        <img src={gallery.avatar} alt={gallery.name} />
        <div className="gallery-info">
          <h3>{gallery.name}</h3>
          <h4>{gallery.photos} images</h4>
        </div>
      </div>
    </Link>
  );
}

export default GalleriesSlideCard;
