"use client";
import MasonryComponent from "@/components/Masonry/Masonry";
import MasonryCard from "@/components/MasonryCard/MasonryCard";
import VisualTest from "@/containers/VisualTest/VisualTest";
import VisualTestProvider from "@/context/VisualTestContext";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./EmmGallery.scss";
import useGalleries from "@/hooks/useGalleries";

type EmmGallery = {
  id?: string;
};
export const EmmGallery = (props: EmmGallery) => {
  const { id } = props;
  const breakpointColumnsObj = {
    default: 5,
    1100: 4,
    700: 3,
    500: 2,
    400: 1,
  };
  const [recommended, setRecommended] = useState<Array<any>>([]);
  const [showRecommended, setShowRecommended] = useState<boolean>(false);
  const [loadingRecommended, setLoadingRecommended] = useState<boolean>(true);
  const [excludedIds, setExcludedIds] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const { getRecommendedGalleries } = useGalleries();
  const fetchMoreGalleries = async () => {
    if (recommended.length > 0) {
      await getRecommendedGalleries(page, excludedIds).then((res) => {
        setPage(page + 1);
        setRecommended([...recommended, ...res]);

        setExcludedIds([
          ...recommended.map((gallery) => gallery?._id),
          ...res.map((gallery: any) => gallery?._id),
        ]);
        setRecommended([...recommended, ...res]);
      });
    }
  };
  useEffect(() => {
    getRecommendedGalleries(page, excludedIds).then((res) => {
      if (res) {
        setRecommended(res);
        setPage(page + 1);
        setLoadingRecommended(false);
        setExcludedIds([...res.map((gallery: any) => gallery._id)]);
      }
    });
  }, []);

  useEffect(() => {
    if (id) {
      const extension = localStorage.getItem("extension");
      if (extension && id === extension) {
        setShowRecommended(true);
        localStorage.removeItem("extension");
      }
    }
  }, [id]);

  return (
    <>
      <InfiniteScroll
        dataLength={recommended.length}
        next={fetchMoreGalleries}
        hasMore={recommended.length < 3000 && showRecommended}
        loader={<h2>loading...</h2>}
      >
        <MasonryComponent
          showRecommended={showRecommended}
          title="Recommended"
          subtitle="Use 'open in the new tab' to open the galleries, if the page is refreshed, you'll have to complete the test again."
          loading={showRecommended && loadingRecommended}
          breakpointColumnsObj={breakpointColumnsObj}
        >
          {recommended &&
            showRecommended &&
            recommended.map((gallery, i) => {
              return (
                <MasonryCard
                  gallery={gallery}
                  key={"gallery-recommend-card-" + i}
                />
              );
            })}
        </MasonryComponent>
      </InfiniteScroll>

      {!showRecommended && (
        <VisualTestProvider>
          <VisualTest />
        </VisualTestProvider>
      )}
    </>
  );
};
