"use client";
import "./GalleriesSlider.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import { A11y, Navigation } from "swiper/modules";
import GalleriesSlideCard from "../GalleriesSlideCard/GalleriesSlideCard";
import { GalleryI } from "@/interfaces/Galleries";
import { useThemeContext } from "@/context/ThemeContext";

interface GallerySliderPropsI {
  title: string;
  loading: boolean;
  gallery: GalleryI[];
}

function GalleriesSlider(props: GallerySliderPropsI) {
  const { loading, gallery } = props;
  const prevButton = useRef<any>(null);
  const nextButton = useRef<any>(null);
  const [swiper, setSwiper] = useState<any>(null);
  const { theme } = useThemeContext();

  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
  }, [loading]);

  return (
    <div className={`galleries-slider-component-main-container ${theme}`}>
      <div className="title-container">
        <h2 className="title">{props.title}</h2>
      </div>
      <div className={`swiper-container `}>
        <Swiper
          modules={[Navigation, A11y]}
          spaceBetween={10}
          slidesPerView={3}
          loop={true}
          navigation={{
            prevEl: prevButton.current,
            nextEl: nextButton.current,
          }}
          breakpoints={{
            901: {
              slidesPerView: 4,
            },
            1100: {
              slidesPerView: 5,
            },
          }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
          onSlideChange={() => {}}
        >
          {gallery.map((gallery, i) => {
            return (
              <SwiperSlide key={"swiper-" + (gallery ? gallery._id : i)}>
                <GalleriesSlideCard gallery={gallery} loading={loading} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          className="custom-swiper-button-prev"
          ref={prevButton}
          onClick={() => prevButton.current?.click()}
        ></div>
        <div
          className="custom-swiper-button-next"
          ref={nextButton}
          onClick={() => nextButton.current?.click()}
        ></div>
      </div>
    </div>
  );
}

export default GalleriesSlider;
