"use client";
import GalleriesSlider from "@/components/GalleriesSlider/GalleriesSlider";
import useGalleries from "@/hooks/useGalleries";
import React, { useEffect, useState } from "react";

export const RecentlyUpdated = () => {
  const [recentlyUpdated, setRecentlyUpdated] = useState([]);
  const [loader, setLoader] = useState(true);
  const { getRecentlyUpdated } = useGalleries();
  useEffect(() => {
    getRecentlyUpdated().then((res) => {
      if (res) {
        setRecentlyUpdated(res);
        setLoader(false);
      }
    });
  }, []);
  if (loader) {
    return <></>;
  }
  return (
    <GalleriesSlider
      title="Recently updated"
      loading={loader}
      gallery={recentlyUpdated}
    />
  );
};
